import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_SCALES, PLAYBACK_SPEED_RATE } from '../../constants';
import { IViewsState } from '../state';

const rawUserScale = localStorage.getItem('userScale');
const userScale =
  rawUserScale && USER_SCALES.indexOf(parseFloat(rawUserScale)) > -1
    ? parseFloat(rawUserScale)
    : 1;

const rawPlaybackSpeed = localStorage.getItem('playbackRate');
const playingSpeed =
  rawPlaybackSpeed &&
  PLAYBACK_SPEED_RATE.indexOf(parseFloat(rawPlaybackSpeed)) > -1
    ? parseFloat(rawPlaybackSpeed)
    : 1;

const rawVolume = localStorage.getItem('playbackVolume');
const volume =
  rawVolume !== null && !Number.isNaN(parseFloat(rawVolume))
    ? Math.max(Math.min(parseFloat(rawVolume), 1), 0)
    : 1;
const muted = !volume;

const initialState: IViewsState = {
  course: {
    tab: 'discover',
  },
  preventDropping: false,
  isRecordingNarration: false,
  userScale,
  slideScale: 1,
  previewSlideScale: 1,
  playingSpeed,
  playing: false,
  playingStepTime: 0,
  volume,
  muted,
  editMode: false,
  stepsDuration: {},
  haveUnsavedChanges: false,
};

export const viewsSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setIsRecordingNarration: (state, action: PayloadAction<boolean>) => {
      state.isRecordingNarration = action.payload;
    },
    setPreventDropping: (state, action: PayloadAction<boolean>) => {
      state.preventDropping = action.payload;
    },
    setPlaying: (state, action: PayloadAction<boolean>) => {
      state.playing = action.payload;
    },
    setPlayingStepTime: (state, action: PayloadAction<number>) => {
      state.playingStepTime = action.payload;
    },
    setStepsDuration: (
      state,
      action: PayloadAction<{ [id: string]: number }>,
    ) => {
      state.stepsDuration = action.payload;
    },
    setPlayingStepId: (state, action: PayloadAction<string | undefined>) => {
      state.playingStepId = action.payload;
    },
    setPlayingSpeed: (state, action: PayloadAction<number>) => {
      localStorage.setItem('playbackRate', action.payload.toString());
      state.playingSpeed = action.payload;
    },
    setVolume: (state, action: PayloadAction<number>) => {
      localStorage.setItem('playbackVolume', action.payload.toString());
      state.volume = action.payload;
      state.muted = !action.payload;
    },
    setMuted: (state, action: PayloadAction<boolean>) => {
      state.muted = action.payload;
    },
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
      state.playing = !action.payload && state.playing;
    },
    setUserScale: (state, action: PayloadAction<number>) => {
      localStorage.setItem('userScale', action.payload.toString());
      state.userScale = action.payload;
    },
    setSlideScale: (state, action: PayloadAction<number>) => {
      state.slideScale = action.payload;
    },
    setPreviewSlideScale: (state, action: PayloadAction<number>) => {
      state.previewSlideScale = action.payload;
    },
    setHaveUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.haveUnsavedChanges = action.payload;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const {
  setPreventDropping,
  setVolume,
  setMuted,
  setPlaying,
  setPlayingStepTime,
  setStepsDuration,
  setEditMode,
  setPlayingSpeed,
  setPlayingStepId,
  setUserScale,
  setSlideScale,
  setPreviewSlideScale,
  setIsRecordingNarration,
  setHaveUnsavedChanges,
} = viewsSlice.actions;

export const { reducer: viewsReducer } = viewsSlice;
