import { UploaderContextState } from './uploader-provider.type';

export const uploaderContextInitialState: UploaderContextState = {
  narration: {
    upload: () => null,
    uploadingNarrations: [],
    helpers: {
      isUploading: () => false,
    },
  },
};
