import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import * as SC from './close-snackbar-action.style';
import { CloseSnackbarActionProps } from './close-snackbar-action.type';

export const CloseSnackbarAction = ({ id }: CloseSnackbarActionProps) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <SC.CloseSnackbarAction onClick={() => closeSnackbar(id)}>
      <Close />
    </SC.CloseSnackbarAction>
  );
};
