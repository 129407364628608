import React from 'react';
import { UploaderProviderProps } from './uploader-provider.type';
import { UploaderContext } from './uploader-context';
import { useUploaderInternal } from './lib/use-uploader-internal';

export const UploaderProvider = ({ children }: UploaderProviderProps) => {
  const value = useUploaderInternal();
  return (
    <UploaderContext.Provider value={value}>
      {children}
    </UploaderContext.Provider>
  );
};
