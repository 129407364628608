import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useCreateCheckoutSessionMutation } from '../../../../api/endpoints/checkout';
import { invariant } from '../../../../utils/invariant';
import { useUser } from '../../../user';
import { useLazyReadCourseQuery } from '../../../../api/endpoints/course';
import { UsePaymentInternalReturn } from './use-payment-internal.type';

export const usePaymentInternal = (): UsePaymentInternalReturn => {
  const navigate = useNavigate();

  const {
    auth: { isLoggedIn },
  } = useUser();

  const [createCheckoutSession, { isLoading: isCreateCheckoutSessionLoading }] =
    useCreateCheckoutSessionMutation();

  const [readCourse, { isLoading: isReadCourseLoading }] =
    useLazyReadCourseQuery();

  const stripe = useStripe();

  const [courseToPurchaseId, setCourseToPurchaseId] = useState<string>();

  const goToCheckout = async (courseId: string) => {
    if (!isLoggedIn) {
      setCourseToPurchaseId(courseId);
      navigate(`/sign-in-purchase`);
      return;
    }

    const courseToPurchase = await readCourse({
      params: { courseId },
    }).unwrap();

    if (courseToPurchase.isPurchased) {
      return;
    }

    invariant(courseId, 'Course ID is undefined');

    setCourseToPurchaseId(undefined);

    const session = await createCheckoutSession({
      payload: { courseId },
    }).unwrap();

    await stripe?.redirectToCheckout({ sessionId: session.id });
  };

  return {
    courseToPurchaseId,
    goToCheckout,
    loading: {
      isGoToCheckoutLoading:
        isReadCourseLoading || isCreateCheckoutSessionLoading,
    },
  };
};
