import { UserContextState } from './user.type';

export const userContextInitialState: UserContextState = {
  auth: {
    isLoggedIn: false,
    login: async () => Promise.resolve(),
    logout: async () => Promise.resolve(),
    resendVerificationEmail: async () => Promise.resolve(),
  },
  password: {
    update: async () => Promise.resolve(),
    reset: async () => Promise.resolve(),
  },
  account: {
    read: async () => Promise.resolve(),
    create: async () => Promise.resolve(),
    update: async () => Promise.resolve(),
    delete: async () => Promise.resolve(),
    persistGuestData: async () => Promise.resolve(),
    loading: {
      isReadAccountLoading: false,
    },
    error: {},
  },
};
