import styled from 'styled-components';

export const SnackbarActions = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;

  .action-button {
    color: white;
  }
`;
