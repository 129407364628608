import { api } from '../../api';
import { CreateFileResponse, CreateFileQueryArgs } from './api-file.type';

export const { useCreateFileMutation } = api.injectEndpoints({
  endpoints: (builder) => ({
    createFile: builder.mutation<CreateFileResponse, CreateFileQueryArgs>({
      query: ({ payload }) => ({
        url: '/assets',
        method: 'post',
        payload,
      }),
    }),
  }),
});
