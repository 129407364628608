import React from 'react';
import * as MUI from '@mui/material';
import { CloseSnackbarAction } from '../../../../components/common/close-snackbar-action';
import * as SC from './snackbar-actions.style';
import { SnackbarActionsProps } from './snackbar-actions.type';

export const SnackbarActions: React.FC<SnackbarActionsProps> = ({
  actions,
  snackbarKey,
}) => (
  <SC.SnackbarActions>
    {actions.map(({ onClick, label }) => (
      <MUI.Button
        key={label}
        variant='text'
        type='button'
        color='secondary'
        onClick={onClick}
        className='action-button'
      >
        {label}
      </MUI.Button>
    ))}
    <CloseSnackbarAction id={snackbarKey} />
  </SC.SnackbarActions>
);
