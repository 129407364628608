export const BYTES_PER_MB = 1024 * 1024;

export const PROGRAMMING_LANGUAGES = {
  javascript: 63,
  python: 71,
  // 👇Multi-file
  web: 89,
};

export const MONACO_LANGUAGES = {
  63: 'javascript',
  71: 'python',
  101: 'html',
  102: 'css',
};

export const INPUT_CODE_BY_LANGUAGE_CODE = {
  63: { a: `const a = prompt("Input a");`, b: `const b = prompt("Input b");` },
  71: { a: `a = input("Input a")`, b: `b = input("Input b")` },
  // TODO: Web Interface.
  89: '',
};

export const INPUT_REPLACE_FOR_LANGUAGE_CODE = {
  63: { a: `const a = `, b: `const b = ` },
  71: { a: `a=`, b: `b=` },
  // TODO: Web Interface.
  89: '',
};

export const COMMENT_BY_LANGUAGE = {
  63: '// ',
  71: '# ',
  89: '// ',
};

export const USER_SCALES = [0.5, 0.75, 0.875, 1, 1.125, 1.25, 1.5, 1.75, 2];
export const PLAYBACK_SPEED_RATE = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
export const EDITOR_CODE_SNIPPET_SCALES = [
  '0.5',
  '0.75',
  '0.875',
  '1',
  '1.125',
  '1.25',
  '1.5',
  '1.75',
  '2',
  '2.25',
  '2.5',
  '2.75',
  '3',
];
export const HIGHLIGHT_SUPPORTED_LANGUAGES = ['javascript', 'python'];

export const SLIDE_ELEMENT_TYPOGRAPHY_VARIANT: {
  [key: string]: 'h1' | 'body1' | 'h4' | 'caption' | 'inherit';
} = {
  title: 'h1',
  text: 'body1',
  subtitle: 'h4',
  caption: 'caption',
  markdown: 'inherit',
  codeSnippet: 'inherit',
};

export const SLIDE_DIMENSIONS = {
  // widthOffset is the approximate width of the steps sidebar
  // the approximate width has been determined manually by eye-balling the calculated resolution scale (for maximum space) where this value is used
  widthResolutionOffset: 400,
  width: 1280,
  height: 720,
  rows: 18,
  columns: 32,
};

export const FEATURE_FLAGS = {
  isPublicProfileEnabled:
    process.env.REACT_APP_FEATURE_FLAG_PUBLIC_PROFILE_ENABLED === 'true',
  isMaintenanceMessageEnabled:
    process.env.REACT_APP_FEATURE_FLAG_MAINTENANCE_ENABLED === 'true',
};

export const FILE_UPLOAD_GENERAL_SIZE_LIMIT_MB =
  Number(process.env.REACT_APP_FILE_UPLOAD_GENERAL_SIZE_LIMIT_MB) || 10;
export const FILE_UPLOAD_AUDIO_SIZE_LIMIT_MB =
  Number(process.env.REACT_APP_FILE_UPLOAD_AUDIO_SIZE_LIMIT_MB) || 40;

// 40MB limit on backend /assets route set by
export const FILE_UPLOAD_GENERAL_SIZE_LIMIT =
  FILE_UPLOAD_GENERAL_SIZE_LIMIT_MB * BYTES_PER_MB;

export const FILE_UPLOAD_AUDIO_SIZE_LIMIT =
  FILE_UPLOAD_AUDIO_SIZE_LIMIT_MB * BYTES_PER_MB;

export const MAINTENANCE_INFO = {
  version: process.env.REACT_APP_MAINTENANCE_BANNER_VERSION || '',
  message: process.env.REACT_APP_MAINTENANCE_BANNER_MESSAGE || '',
  title: process.env.REACT_APP_MAINTENANCE_BANNER_TITLE || '',
};

// We will adjust it in the settings later
export const WAITLIST_LINK = 'https://forms.gle/eh4cf288HMGTE3d2A';
