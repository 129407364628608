import { api } from '../../api';
import { API_TAGS } from '../../api-tags';
import {
  CreateAnimationQueryArgs,
  CreateAnimationResponse,
  DeleteAnimationQueryArgs,
  DeleteAnimationResponse,
  UpdateAnimationQueryArgs,
  UpdateAnimationResponse,
} from './api-animation.type';

export const {
  useCreateAnimationMutation,
  useUpdateAnimationMutation,
  useDeleteAnimationMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    createAnimation: builder.mutation<
      CreateAnimationResponse,
      CreateAnimationQueryArgs
    >({
      query: ({ payload }) => ({
        url: '/animatedStateChanges',
        method: 'post',
        payload,
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),

    updateAnimation: builder.mutation<
      UpdateAnimationResponse,
      UpdateAnimationQueryArgs
    >({
      query: ({ params, payload }) => ({
        url: `/animatedStateChanges/${params.id}`,
        method: 'patch',
        payload,
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),

    deleteAnimation: builder.mutation<
      DeleteAnimationResponse,
      DeleteAnimationQueryArgs
    >({
      query: ({ params }) => ({
        url: `/animatedStateChanges/${params.id}`,
        method: 'delete',
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),
  }),
});
