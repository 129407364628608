import {
  ElementType,
  ElementPosition,
  CreateElementPayload,
  CreateElementPayloadCodeEditor,
} from '../../../../../../api/endpoints/element';
import { SLIDE_DIMENSIONS } from '../../../../../../constants';
import {
  CreateElementParams,
  DuplicateElementQueryParams,
} from '../use-elements-mutations.type';

const { columns: maxWidth, rows: maxHeight } = SLIDE_DIMENSIONS;

export const positionByElementType: Record<ElementType, ElementPosition> = {
  code: {
    w: maxWidth,
    h: maxHeight,
    minW: 0,
    minH: 0,
    x: 0,
    y: 0,
  },
  frontendCode: {
    w: maxWidth,
    h: maxHeight,
    minW: 0,
    minH: 0,
    x: 0,
    y: 0,
  },
  quiz: {
    w: maxWidth,
    h: maxHeight,
    minW: 0,
    minH: 0,
    x: 0,
    y: 0,
  },
  title: {
    w: Math.round((maxWidth / 8) * 5),
    h: 4,
    minW: 4,
    minH: 2,
    x: 2,
    y: 1,
  },
  subtitle: {
    w: Math.round(maxWidth / 2),
    h: 3,
    minW: 4,
    minH: 2,
    x: 2,
    y: 5,
  },
  text: {
    w: 4,
    h: 1,
    minW: 2,
    minH: 1,
    x: 2,
    y: 5,
  },
  caption: {
    w: Math.round(maxWidth / 4),
    h: 1,
    minW: 1,
    minH: 1,
    x: Math.round((maxWidth / 8) * 5),
    y: Math.round((maxHeight / 3) * 2),
  },
  image: {
    w: Math.round(maxWidth / 2),
    h: Math.round(maxHeight / 2),
    minW: 1,
    minH: 1,
    x: Math.round((maxWidth / 8) * 3),
    y: Math.round(maxHeight / 6),
  },
  codeSnippet: {
    w: Math.round((maxWidth / 4) * 3),
    h: 3,
    minW: 4,
    minH: 2,
    x: Math.round(maxWidth / 4),
    y: Math.round(maxHeight / 4),
  },
  markdown: {
    w: Math.round((maxWidth / 8) * 3),
    h: 4,
    minW: 4,
    minH: 2,
    x: 2,
    y: 5,
  },
  customHTMLSlide: {
    w: maxWidth,
    h: maxHeight,
    minW: 0,
    minH: 0,
    x: 0,
    y: 0,
  },
  pointer: {
    w: maxWidth,
    h: maxHeight,
    minW: 0,
    minH: 0,
    x: 0,
    y: 0,
  },
};

export const getPayloadByElementType = (
  element:
    | CreateElementParams['payload']
    | DuplicateElementQueryParams['payload'],
) => {
  let payload: CreateElementPayload;

  if (element.type === 'code') {
    payload = {
      ...element,
      value: {
        content: '' as const,
        language: {
          // STFI Warning
          id: 'IvHC5EHRGBxXFxVHv0Cc16CJ0jQVGCVX',
        },
        input: {
          content: '',
          editable: false,
          visible: false,
        },
        output: {
          visible: true,
        },
        terminal: {
          visible: false,
        },
        visualiser: {
          enabled: false,
          showScopes: false,
        },
        packages: {
          content: '',
        },
        sourceCode: [
          {
            editable: true,
            name: 'script.js',
            visible: true,
            splitted: false,
            body: '',
          },
        ],
        testCases: {
          generalHint: '',
          isAssignment: false,
          tests: [],
        },
        isCodeSolution: false,
        task: {
          title: '',
          content: '',
        },
      },
    } as CreateElementPayloadCodeEditor;
  } else if (element.type === 'frontendCode') {
    payload = {
      ...element,
      value: {
        content: '' as const,
        sourceCode: [
          {
            editable: true,
            name: 'index.html',
            visible: true,
            splitted: false,
            body: '',
          },
          {
            editable: true,
            name: 'style.css',
            visible: true,
            splitted: false,
            body: '',
          },
          {
            editable: true,
            name: 'script.js',
            visible: true,
            splitted: false,
            body: '',
          },
        ],
        testCases: {
          generalHint: '',
          isAssignment: false,
          tests: [],
        },
        isCodeSolution: false,
        task: {
          title: '',
          content: '',
        },
        isShareable: false,
      },
    };
  } else if (element.type === 'codeSnippet') {
    payload = {
      ...element,
      value: {
        content: element.value?.content || '',
        fontSize: element.value?.fontSize || '',
        language: element.value?.language || '',
      },
    };
  } else if (element.type === 'quiz') {
    payload = {
      ...element,
      value: {
        content: element.value?.content || '',
        answers: element.value?.answers || [
          {
            content: '',
            isCorrect: false,
            explanation: '',
          },
        ],
        isRandomOrder: element.value?.isRandomOrder || false,
      },
    };
  } else if (element.type === 'customHTMLSlide') {
    payload = {
      ...element,
      value: {
        content: '',
        sourceCode: [
          {
            name: 'index.html',
            body: '',
          },
          {
            name: 'style.css',
            body: '',
          },
          {
            name: 'script.js',
            body: '',
          },
        ],
      },
    };
  } else if (element.type === 'pointer') {
    payload = element;
  } else {
    payload = {
      ...element,
      value: {
        content: element.value?.content || '',
      },
    };
  }

  return payload;
};
