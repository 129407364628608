import { api } from '../../api';
import { API_TAGS } from '../../api-tags';
import {
  CreateCheckoutSessionQueryArgs,
  CreateCheckoutSessionResponse,
  ReadPurchaseInvoiceQueryArgs,
  ReadPurchaseInvoiceResponse,
  ReadPurchaseReceiptQueryArgs,
  ReadPurchaseReceiptResponse,
  ReadPurchasesResponse,
  RefundPurchaseQueryArgs,
} from './api-checkout.type';

export const {
  useCreateCheckoutSessionMutation,
  useReadPurchasesQuery,
  useLazyReadPurchaseReceiptQuery,
  useLazyReadPurchaseInvoiceQuery,
  useRefundPurchaseMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation<
      CreateCheckoutSessionResponse,
      CreateCheckoutSessionQueryArgs
    >({
      query: ({ payload }) => ({
        url: '/checkout/create-checkout-session',
        method: 'post',
        payload,
      }),
      invalidatesTags: (_result, error) => (error ? [] : [API_TAGS.purchase()]),
    }),
    readPurchases: builder.query<ReadPurchasesResponse, void>({
      query: () => ({
        url: '/checkout/purchases',
        method: 'get',
      }),
      providesTags: (_result, error) => (error ? [] : [API_TAGS.purchase()]),
    }),
    readPurchaseReceipt: builder.query<
      ReadPurchaseReceiptResponse,
      ReadPurchaseReceiptQueryArgs
    >({
      query: ({ params: { purchaseId } }) => ({
        url: `/checkout/purchases/${purchaseId}/receipt`,
        method: 'get',
      }),
    }),
    readPurchaseInvoice: builder.query<
      ReadPurchaseInvoiceResponse,
      ReadPurchaseInvoiceQueryArgs
    >({
      query: ({ params: { purchaseId } }) => ({
        url: `/checkout/invoices/${purchaseId}`,
        method: 'get',
      }),
    }),
    refundPurchase: builder.mutation<void, RefundPurchaseQueryArgs>({
      query: ({ payload }) => ({
        url: `/checkout/refund`,
        method: 'post',
        payload,
      }),
      invalidatesTags: (_result, error, { params: { courseId } }) =>
        error ? [] : [API_TAGS.purchase(), API_TAGS.course(courseId)],
    }),
  }),
});
