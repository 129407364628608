import Bugsnag from '@bugsnag/browser';
import { elementStorageService } from '../../../storage/element';
import { isUserLoggedIn } from '../../../providers/user';
import { enqueueSnackbar } from '../../../utils/snackbar-helper';
import { isInsideIframe } from '../../../utils/is-inside-iframe';
import {
  ReadElementUserValueQueryArgs,
  ReadElementUserValueResponse,
} from './api-element.type';

export const transformStorageReadElementUserValue = async (
  elementUserValue: ReadElementUserValueResponse,
  _: unknown,
  queryArgs: ReadElementUserValueQueryArgs,
): Promise<ReadElementUserValueResponse> => {
  if (isUserLoggedIn() && !isInsideIframe()) {
    return elementUserValue;
  }

  try {
    const offlineElement = await elementStorageService.readOne(
      queryArgs.params.id,
    );

    if (!offlineElement) {
      return elementUserValue;
    }

    return { ...elementUserValue, value: offlineElement.value, exists: true };
  } catch (error) {
    if (error instanceof Error) {
      Bugsnag.notify(error);
    }

    enqueueSnackbar(
      'An error occurred while reading element user value as a guest user. Please try again or reach out to support.',
      { variant: 'error' },
    );

    return elementUserValue;
  }
};
