import { api } from '../../api';
import { API_TAGS } from '../../api-tags';
import {
  CreateStepResponse,
  CreateStepQueryArgs,
  DeleteStepResponse,
  DeleteStepQueryArgs,
  CreateStepNarrationResponse,
  CreateStepNarrationQueryArgs,
  DeleteStepNarrationResponse,
  DeleteStepNarrationQueryArgs,
} from './api-step.type';

export const {
  useCreateStepMutation,
  useDeleteStepMutation,
  useCreateStepNarrationMutation,
  useDeleteStepNarrationMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    createStep: builder.mutation<CreateStepResponse, CreateStepQueryArgs>({
      query: ({ params, payload }) => ({
        url: `/slides/${params.slideId}/steps`,
        method: 'post',
        payload: {
          ...payload,
          // FIXME: once path of the backend endpoint is changed to lessons/{lessonId}/slides/{slideId}/steps
          // remove lessonId from the payload and include in the url
          lessonId: params.lessonId,
        },
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),

    deleteStep: builder.mutation<DeleteStepResponse, DeleteStepQueryArgs>({
      query: ({ params }) => ({
        url: `/slides/${params.slideId}/steps/${params.id}`,
        method: 'delete',
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),

    createStepNarration: builder.mutation<
      CreateStepNarrationResponse,
      CreateStepNarrationQueryArgs
    >({
      query: ({ params, payload }) => ({
        url: `/narrations`,
        method: 'post',
        payload: {
          ...payload,
          stepId: params.stepId,
          lessonId: params.lessonId,
        },
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),

    deleteStepNarration: builder.mutation<
      DeleteStepNarrationResponse,
      DeleteStepNarrationQueryArgs
    >({
      query: ({ params }) => ({
        url: `/narrations/${params.id}`,
        method: 'delete',
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.lesson(params.lessonId)],
    }),
  }),
});
