import styled from 'styled-components';
import * as MUI from '@mui/material';

export const MaintenanceDialog = styled(MUI.Card)`
  position: fixed;
  z-index: 1300;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  background-color: #004a41;
  width: 25%;
  .actions {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }
`;
