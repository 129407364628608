import { api } from '../../api';
import { API_TAGS } from '../../api-tags';
import { transformStorageReadElementUserValue } from './api-element.storage-transformer';
import {
  CreateElementQueryArgs,
  CreateElementResponse,
  DeleteElementQueryArgs,
  DeleteElementResponse,
  ReadElementUserValueQueryArgs,
  ReadElementUserValueResponse,
  UpdateElementQueryArgs,
  UpdateElementResponse,
  UpdateElementUserValueQueryArgs,
  UpdateElementUserValueResponse,
} from './api-element.type';

export const {
  useCreateElementMutation,
  useUpdateElementMutation,
  useUpdateElementUserValueMutation,
  useUpdateElementQuizUserValueMutation,
  useLazyReadElementUserValueQuery,
  useReadElementUserValueQuery,
  useDeleteElementMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    createElement: builder.mutation<
      CreateElementResponse,
      CreateElementQueryArgs
    >({
      query: ({ payload }) => ({
        url: '/slideElements',
        method: 'post',
        payload,
      }),
      invalidatesTags: (_result, error, { params: { lessonId } }) =>
        error ? [] : [API_TAGS.lesson(lessonId)],
    }),

    readElementUserValue: builder.query<
      ReadElementUserValueResponse,
      ReadElementUserValueQueryArgs
    >({
      query: ({ params, query }) => ({
        url: `/users/slideElement/${params.id}?courseId=${query.courseId}`,
        method: 'get',
      }),
      transformResponse: transformStorageReadElementUserValue,
      providesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.userElementValue(params.id)],
    }),

    updateElement: builder.mutation<
      UpdateElementResponse,
      UpdateElementQueryArgs
    >({
      query: ({ params, payload }) => ({
        url: `/slideElements/${params.id}`,
        method: 'patch',
        payload,
      }),
      invalidatesTags: (_result, error, { params: { lessonId } }) =>
        error ? [] : [API_TAGS.lesson(lessonId)],
    }),

    updateElementUserValue: builder.mutation<
      UpdateElementUserValueResponse,
      UpdateElementUserValueQueryArgs
    >({
      query: ({ params, payload }) => ({
        url: `/users/slideElement/${params.id}`,
        path: `/users/slideElement/:id`,
        params,
        method: 'put',
        payload,
      }),
      invalidatesTags: (
        _result,
        error,
        { params: { lessonId, id: slideElementId } },
      ) =>
        error
          ? []
          : [
              API_TAGS.lesson(lessonId),
              API_TAGS.shareUrl(slideElementId),
              API_TAGS.userElementValue(slideElementId),
            ],
    }),

    // clone of the `updateElementUserValue` above with reduced tag invalidation
    updateElementQuizUserValue: builder.mutation<
      UpdateElementUserValueResponse,
      UpdateElementUserValueQueryArgs
    >({
      query: ({ params, payload }) => ({
        url: `/users/slideElement/${params.id}`,
        path: `/users/slideElement/:id`,
        params,
        method: 'put',
        payload,
      }),
      invalidatesTags: (_result, error, { params: { id: slideElementId } }) =>
        error ? [] : [API_TAGS.userElementValue(slideElementId)],
    }),

    deleteElement: builder.mutation<
      DeleteElementResponse,
      DeleteElementQueryArgs
    >({
      query: ({ params }) => ({
        url: `/slideElements/${params.id}`,
        method: 'delete',
      }),
      invalidatesTags: (_result, error, { params: { lessonId } }) =>
        error ? [] : [API_TAGS.lesson(lessonId)],
    }),
  }),
});
