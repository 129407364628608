import Bugsnag from '@bugsnag/browser';
import { STORAGE_USE_ERROR_MESSAGE } from '../../../storage';
import { courseStorageService } from '../../../storage/course';
import { isUserLoggedIn } from '../../../providers/user';
import { enqueueSnackbar } from '../../../utils/snackbar-helper';
import { isInsideIframe } from '../../../utils/is-inside-iframe';
import { transformResponseReadCourse } from './api-course.transformer';
import { Course } from './api-course.type';

export const updateStorageReadCourse = async (
  course: Awaited<ReturnType<typeof transformResponseReadCourse>>,
): Promise<Course> => {
  if (isUserLoggedIn() && !isInsideIframe()) {
    return course;
  }

  try {
    await courseStorageService.create(course);
  } catch (error) {
    if (error instanceof Error) {
      Bugsnag.notify(error);
    }
    enqueueSnackbar(STORAGE_USE_ERROR_MESSAGE, { variant: 'error' });
  }

  return course;
};
