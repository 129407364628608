import { isInsideIframe } from '../utils/is-inside-iframe';
import { codeSubmissionStorageControllers } from './code-submission';
import { courseStorageControllers, courseStorageService } from './course';
import { elementStorageControllers, elementStorageService } from './element';
import { lessonStorageControllers, lessonStorageService } from './lesson';
import { DB_NAME, DB_LP_NAME } from './storage-api.constant';
import { GuestData, StorageControllerPath } from './storage-api.type';

export const storageApi = {
  request: async (path: StorageControllerPath, args: unknown) => {
    const controllers = {
      ...lessonStorageControllers,
      ...courseStorageControllers,
      ...elementStorageControllers,
      ...codeSubmissionStorageControllers,
    };
    // too hard (unfeasible?) to type args for little (zero?) type-safety gain
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return controllers[path](args);
  },

  readAll: async (): Promise<GuestData> => {
    const courses = await courseStorageService.readMany();

    const lessons = await lessonStorageService.readMany();
    const lessonsTransformed = lessons.map(
      ({ id, selectedSlideId, seenSlideIds }) => ({
        id,
        selectedSlideId,
        seenSlideIds,
      }),
    );

    const elements = await elementStorageService.readMany();

    return {
      courses,
      lessons: lessonsTransformed,
      elements,
    };
  },

  clear: () => {
    indexedDB.deleteDatabase(isInsideIframe() ? DB_LP_NAME : DB_NAME);
  },
};
